import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";
import { logout } from "./../enterpriseServices";
import getmeeicon from "./../../../assets/images/getmee-watermark.svg";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  handleProfileRedirect = (e) => {
    e.preventDefault();
    this.props.history.replace("/enterprise/profile");
  };

  handleLogout = (e) => {
    e.preventDefault();

    this.props.dispatch(logout());
  };

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
            {localStorage.getItem("loginAsEnterprise") ? (
              <React.Fragment>
                <img
                  className="rounded-circle header-profile-user mr-1"
                  src={getmeeicon}
                  alt="Getmee"
                />
                <span className="d-none d-xl-inline-block ml-1 text-transform">
                  Getmee Admin
                </span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <img
                  className="rounded-circle header-profile-user mr-1"
                  src={this.props.user.avatarUrl}
                  alt={this.props.user.name}
                />
                <span className="d-none d-xl-inline-block ml-1 text-transform">
                  {this.props.user.name}
                </span>
              </React.Fragment>
            )}
            <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-lg" right>
            <div className="px-lg-2">
              <Row className="no-gutters">
                <Col xl={12} sm={12}>
                  <Row>
                    {localStorage.getItem("loginAsEnterprise") ? (
                      <React.Fragment>
                        <Col xs={4}>
                          <img
                            src={getmeeicon}
                            alt="Getmee"
                            className="avatar-lg mt-2"
                          />
                        </Col>
                        <Col xs={8} className="mt-3">
                          <h5>Getmee Admin</h5>
                          <p className="text-muted mt-2">
                            <strong>Admin</strong>
                            <br />
                            hi@getmee.ai
                          </p>
                          <button
                            onClick={this.handleProfileRedirect}
                            className="btn btn-primary btn-sm"
                          >
                            View Profile
                          </button>
                        </Col>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Col xs={4}>
                          <img
                            src={this.props.user.avatarUrl}
                            alt={this.props.user.name}
                            className="avatar-lg mt-2"
                          />
                        </Col>
                        <Col xs={8} className="mt-3">
                          <h5>{this.props.user.name}</h5>
                          <p className="text-muted mt-2">
                            <strong>{this.props.user.roleId.name}</strong>
                            <br />
                            {this.props.user.email}
                          </p>
                          <button
                            onClick={this.handleProfileRedirect}
                            className="btn btn-primary btn-sm"
                          >
                            View Profile
                          </button>
                        </Col>
                      </React.Fragment>
                    )}
                  </Row>
                  <hr className="my-4" />
                  {/* <DropdownItem className="d-block" href="#">
                    Enterprise Plan
                  </DropdownItem> */}
                  {this.props.user?.roleId.name &&
                    this.props.user?.roleId?.name.toLowerCase() == "admin" && (
                      <DropdownItem
                        className="d-block"
                        tag={Link}
                        to="/enterprise/settings"
                      >
                        Settings
                      </DropdownItem>
                    )}
                  <DropdownItem
                    className="d-block"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Privacy Policy
                  </DropdownItem>
                  <DropdownItem
                    className="d-block"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Terms & Condition
                  </DropdownItem>
                  <DropdownItem
                    className="mt-2 text-danger text-center"
                    href="#"
                    onClick={this.handleLogout}
                  >
                    <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "}
                    Logout
                  </DropdownItem>
                </Col>
              </Row>
            </div>
          </DropdownMenu>
          {/* <DropdownMenu className="dropdown-menu-lg" right>
            <DropdownItem href="#" onClick={this.handleProfileRedirect}>
              <i className="ri-user-line align-middle mr-1"></i> Profile
            </DropdownItem>
            <DropdownItem className="d-block" href="#">
              <span className="badge badge-success float-right mt-1">11</span>
              <i className="ri-settings-2-line align-middle mr-1"></i> Settings
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              className="text-danger"
              href="#"
              onClick={this.handleLogout}
            >
              <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "}
              Logout
            </DropdownItem>
          </DropdownMenu> */}
        </Dropdown>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

export default withRouter(connect(mapStateToProps)(ProfileMenu));
